/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"


let UserRegLeads = {
  /**
   * userRegLeadList
   */
  async userRegLeadList (context, whereFilter=null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_reg_lead_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRegLeadList() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * userRegLeadView
   */
  async userRegLeadView (context, userRegLeadId) {
    try {      
      let post_data = new FormData();
      post_data.append('usrl_id', userRegLeadId);
      return await request.curl(context, "user_reg_lead_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at userRegLeadView() and Exception:",err.message)
    }    
  },

  /**
   * userRegLeadAdd
   */
  async userRegLeadAdd (context, userRegLeadObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userRegLeadObj) {
        post_data.append(key, userRegLeadObj[key]);
      }

      return await request.curl(context, "user_reg_lead_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRegLeadAdd() and Exception:',err.message)
    }
  },

  /**
   * userRegLeadEdit
   */
  async userRegLeadEdit (context, userRegLeadObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userRegLeadObj) {
        post_data.append(key, userRegLeadObj[key]);
      }

      return await request.curl(context, "user_reg_lead_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRegLeadEdit() and Exception:',err.message)
    }
  },

  /**
   * userRegLeadDelete
   */
  async userRegLeadDelete (context, userRegLeadId) {
    try{
      let post_data = new FormData();
      
      post_data.append('usrl_id', userRegLeadId);

      return await request.curl(context, "user_reg_lead_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at userRegLeadDelete() and Exception:',err.message)
    }
  },

  /**
   * studentRegLeadList
   */
   async studentRegLeadList (context, whereFilter = null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append("filter", JSON.stringify(whereFilter))
      }
      return await request.curl(context, "student_reg_lead_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at userRegLeadList() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * Student Course Name/Product Filter
  */
  async studentFilterList (context, filterName = null) {
    if (!filterName){
      return
    }

    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      post_data.append("filter_name", filterName);

      return await request.curl(context, "student_filter_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at student_filter_list() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  }
  
}

export {
  UserRegLeads
}
